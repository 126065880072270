import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
} from "react-router-dom";
import Navigation from "./components/navigation/navigation";
import Footer from "./components/footer/footer";
import Home from "./pages/home/home";
import Contact from "./pages/contact/contact";
import About from "./pages/about/about";
import AaCompanion from "./pages/aacompanion/aacompanion";
import NotFound from "./pages/notfound/notfound";
import "./App.css";

function App(args) {
  return (
    <Router>
      <Navigation />
      <main>
        <Routes>
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/aa-companion" element={<AaCompanion />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
