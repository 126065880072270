import React from "react";
import { Container, Row, Col } from "reactstrap";
import iconBgg from "../../assets/images/social-icons/icon-bgg.png";
import medusaQr from "../../assets/images/medusa-gaming/qr-code-medusa-gaming-website.png";
import "./contact.css";

export default function Contact(props) {
  const {} = props;

  return (
    <>
      <Container className="marketing pt-4">
        <Row className="text-center">
          <Col lg="9" md="7" className="mx-auto">
            <h1 className="display-4">Get in touch</h1>
            <p className="lead">We'd love to hear from you!</p>
          </Col>
        </Row>
      </Container>
      <Container className="marketing py-4">
        <div className="p-4 mb-4 bg-light rounded-3 mt-2">
          <Row>
            <Col xs="12" sm="8" md="8" lg="8" xl="8">
              <Row>
                <Col className="offset-xs-0 offset-md-2">
                  <p className="lead">Email us directly on</p>
                  <a href="mailto:perseus@medusagaming.org" target="_blank">
                    perseus@medusagaming.org
                  </a>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="offset-xs-0 offset-md-2">
                  <p className="lead">Or connect with us via social media:</p>
                </Col>
              </Row>
              <Row>
                <Col className="offset-xs-0 offset-md-2">
                  <div style={{ display: "inline-block" }}>
                    <img
                      src={iconBgg}
                      alt="Medusa Gaming Logo"
                      className="d-inline-block align-text-top"
                      style={{ maxWidth: "2em", maxHeight: "2.2em" }}
                    />
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <a
                    href="https://boardgamegeek.com/user/medusagaming"
                    target="_blank"
                  >
                    boardgamegeek.com/user/medusagaming
                  </a>
                </Col>
              </Row>
              <Row>
                <Col className="offset-xs-0 offset-md-2">
                  <span className="align-middle">
                    <i
                      className="bi bi-instagram"
                      style={{ fontSize: "2rem", color: "black" }}
                    ></i>
                  </span>
                  &nbsp;&nbsp;
                  <a
                    href="https://www.instagram.com/medusa.tabletop/"
                    target="_blank"
                  >
                    instagram.com/medusa.tabletop/
                  </a>
                </Col>
              </Row>
              <Row>
                <Col className="offset-xs-0 offset-md-2">
                  <span className="align-middle">
                    <i
                      className="bi bi-steam"
                      style={{ fontSize: "2rem", color: "black" }}
                    ></i>
                  </span>
                  &nbsp;&nbsp;
                  <a
                    href="https://steamcommunity.com/id/v1nd1cator"
                    target="_blank"
                  >
                    steamcommunity.com/id/v1nd1cator
                  </a>
                </Col>
              </Row>
              <Row>
                <Col className="offset-xs-0 offset-md-2">
                  <span className="align-middle">
                    <i
                      className="bi bi-facebook"
                      style={{ fontSize: "2rem", color: "black" }}
                    ></i>
                  </span>
                  &nbsp;&nbsp;
                  <a
                    href="https://www.facebook.com/medusa.tabletop"
                    target="_blank"
                  >
                    facebook.com/medusa.tabletop
                  </a>
                </Col>
              </Row>
              <Row>
                <Col className="offset-xs-0 offset-md-2">
                  <span className="align-middle">
                    <i
                      className="bi bi-linkedin"
                      style={{ fontSize: "2rem", color: "black" }}
                    ></i>
                  </span>
                  &nbsp;&nbsp;
                  <a
                    href="https://www.linkedin.com/in/denis-phoenix/"
                    target="_blank"
                  >
                    linkedin.com/in/denis-phoenix/
                  </a>
                </Col>
              </Row>
            </Col>
            <Col xs="6" sm="6" md="4" lg="4" xl="3" className="text-center">
              <img
                src={medusaQr}
                alt="Medusa Gaming QR"
                className="d-inline-block align-text-top img-fluid mt-4"
                // style={{ maxWidth: "2em", maxHeight: "2.2em" }}
              />
              <p className="lead">Scan to open this website</p>
            </Col>
          </Row>
        </div>
      </Container>

      {/* <Container className="marketing py-4">
        <Row>
          <Col>
            <section className="container">
              <div className="row">
                <div className="col-lg-9 col-md-7">
                  <h1 className="display-6">Connect via social media</h1>
                  <p className="lead">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Maecenas pretium malesuada sapien, id convallis ipsum
                    feugiat vel. Nulla non leo mi.
                  </p>
                </div>
                <Row>
                  <Col>
                    <a
                      href="https://boardgamegeek.com/user/medusagaming"
                      target="_blank"
                    >
                      <img
                        src={iconBgg}
                        alt="Medusa Gaming Logo"
                        className="d-inline-block align-text-top"
                        style={{ maxWidth: "2em", maxHeight: "2.2em" }}
                      />
                    </a>
                  </Col>
                  <Col>BoardGameGeek</Col>
                </Row>
              </div>
            </section>
          </Col>
        </Row>
      </Container> */}

      {/* 
          Use a service like EmailJS (https://www.emailjs.com/docs/examples/reactjs/) to send emails from the UI
          <Col>
            <h1 className="display-6">Or send us a message</h1>
            <p className="lead">
              Please share your thoughts with us - we always welcome feedback!
            </p>
            <br />
            <form>
              <div className="mb-3 col-6">
                <label htmlFor="email" className="form-label">
                  Your email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                />
                <div id="emailHelp" className="form-text">
                  We'll never share your email with anyone else.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Your message
                </label>
                <textarea
                  className="form-control"
                  placeholder="Leave your message here"
                  id="message"
                  style={{ height: "120px" }}
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Send
              </button>
            </form>
          </Col> */}
    </>
  );
}
