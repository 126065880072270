import React, { useState } from "react";
import {
  Button,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import { getSymbolUnicode } from "../../functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiceOne,
  faDiceTwo,
  faDiceThree,
  faDiceFour,
  faDiceFive,
  faDiceSix,
} from "@fortawesome/free-solid-svg-icons";
import wrapped from "../../assets/images/alchemists-apprentice/wrapped.png";
import "./die.css";

export default function Die(props) {
  //console.log("Die props", props);

  const {
    id,
    type,
    numberOfFaces,
    selectedValue,
    isRolling,
    isAssigned,
    isReserved,
    isWrapped,
    changeDieValue,
    toggleAssigned,
    toggleReserved,
    toggleWrapped,
    reRollOptionVisible,
    reRollDie,
    flipDie,
    wrapOptionVisible,
    flipOptionVisible,
  } = props;

  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  const handleClick = (event) => {
    setPopoverIsOpen(!popoverIsOpen);
  };

  const d6Icons = [
    faDiceOne,
    faDiceTwo,
    faDiceThree,
    faDiceFour,
    faDiceFive,
    faDiceSix,
  ];

  return (
    <>
      <Button
        color="light"
        className="die-button"
        id={id}
        onClick={() => handleClick()}
      >
        {numberOfFaces === 6 && (
          <FontAwesomeIcon
            icon={d6Icons[selectedValue - 1]}
            className={`die-image
                die-${type}
                ${isRolling && "die-shaking"}`}
          />
        )}

        {numberOfFaces !== 6 && (
          <div
            className={`die-non-d6-div die-${type} ${
              isRolling && "die-shaking"
            }`}
          >
            <span className={`die-non-d6-span`}>{selectedValue}</span>
          </div>
        )}

        {isWrapped && (
          <div id={`wrapped-${id}`} className="img-wrapper">
            <img src={wrapped} className="img-fluid rounded" alt="Wrapped" />
          </div>
        )}
      </Button>

      <UncontrolledPopover flip target={id} placement="bottom" trigger="legacy">
        <PopoverHeader>
          Die options
          {type === "volatility" && <span>&nbsp;({type})</span>}
          {type !== "volatility" && (
            <span>
              &nbsp;({type} {getSymbolUnicode(type)})
            </span>
          )}
        </PopoverHeader>
        <PopoverBody>
          {!isAssigned && type !== "mineral" && (
            <div>
              {[...Array(numberOfFaces)].map((die, index) => (
                <Button
                  color="light"
                  className="die-button"
                  key={`span-${index}`}
                  onClick={() => changeDieValue(id, index + 1)}
                >
                  {numberOfFaces === 6 && (
                    <FontAwesomeIcon
                      icon={d6Icons[index]}
                      className={`die-image-popover die-${type}`}
                    />
                  )}

                  {numberOfFaces !== 6 && (
                    <div className={`die-non-d6-div die-${type}`}>
                      <span className={`die-non-d6-span`}>{index + 1}</span>
                    </div>
                  )}
                </Button>
              ))}
            </div>
          )}

          <div className="btn-group-vertical">
            {type !== "volatility" && (
              <div className="mt-2">
                <Button
                  color="success"
                  outline
                  onClick={() => toggleAssigned(id, !isAssigned)}
                >
                  {isAssigned ? "Unassign" : "Assign"}
                </Button>
              </div>
            )}
            {type !== "volatility" && type !== "mineral" && !isAssigned && (
              <div className="mt-2">
                <Button
                  color="success"
                  outline
                  onClick={() => toggleReserved(id, !isReserved)}
                >
                  {isReserved ? "Unreserve" : "Reserve"}
                </Button>
              </div>
            )}
            {flipOptionVisible &&
              !isAssigned &&
              !isReserved &&
              type !== "mineral" && (
                <div className="mt-2">
                  <Button color="success" outline onClick={() => flipDie(id)}>
                    Flip
                  </Button>
                </div>
              )}
            {reRollOptionVisible && !isAssigned && type !== "mineral" && (
              <div className="mt-2">
                <Button color="success" outline onClick={() => reRollDie(id)}>
                  Reroll
                </Button>
              </div>
            )}
            {wrapOptionVisible &&
              type !== "volatility" &&
              type !== "mineral" &&
              !isAssigned &&
              !isReserved && (
                <div className="mt-2">
                  <Button
                    color="success"
                    outline
                    onClick={() => toggleWrapped(id, !isWrapped)}
                  >
                    {isWrapped ? "Unwrap" : "Wrap"}
                  </Button>
                </div>
              )}
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
