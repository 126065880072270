export const dicetypes = [
  {
    type: "volatility",
    numberOfFaces: 6,
  },
  {
    type: "salt",
    numberOfFaces: 6,
  },
  {
    type: "fire",
    numberOfFaces: 6,
  },
  {
    type: "water",
    numberOfFaces: 6,
  },
  {
    type: "earth",
    numberOfFaces: 6,
  },
  {
    type: "brimstone",
    numberOfFaces: 6,
  },
  {
    type: "mineral",
    numberOfFaces: 6,
  },
  {
    type: "air",
    numberOfFaces: 6,
  },
  {
    type: "arsenic",
    numberOfFaces: 4,
  },
  {
    type: "iron",
    numberOfFaces: 8,
  },
  {
    type: "mandrake",
    numberOfFaces: 10,
  },
  {
    type: "quicksilver",
    numberOfFaces: 12,
  },
  {
    type: "secret",
    numberOfFaces: 20,
  },
];
