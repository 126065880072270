import React from "react";
import { Container, Row, Col } from "reactstrap";
import notFoundImage from "../../assets/images/page-not-found.jpg";

export default function NotFound(props) {
  const {} = props;

  return (
    <>
      <Container className="marketing py-4">
        <section className="text-center container">
          <Row>
            <Col md="7" lg="9" className="mx-auto">
              <h1 className="display-4">Uh oh!</h1>
              <p className="lead">
                Have you seen this goat? Looks like he may have eaten the page
                you're looking for!
              </p>
              <p className="lead">
                <a href="/" className="link-primary">
                  Click here
                </a>{" "}
                to go back to the home page.
              </p>
              <img src={notFoundImage} className="img-fluid" />
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
}
