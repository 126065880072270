import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import medusaLogo from "../../assets/images/medusa-gaming/medusa-gaming-logo.png";

export default function Navigation(args) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <header>
      <Navbar
        light
        color="light"
        className="navbar-expand-lg bg-light sticky-top"
        {...args}
      >
        <Col xs={9} sm={5} md={4} lg={4} xl={3}>
          <NavbarBrand href="/">
            <img
              src={medusaLogo}
              alt="Medusa Gaming Logo"
              className="d-inline-block align-text-top img-fluid"
            />
          </NavbarBrand>
        </Col>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar className="mx-4">
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink href="/">Home</NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink href="/about">About</NavLink>
            </NavItem> */}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Apps
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem>
                  <NavItem>
                    <NavLink href="/aa-companion">
                      Alchemist's Apprentice Companion
                    </NavLink>
                  </NavItem>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink href="/contact">Contact</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
}
