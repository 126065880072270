export const practicumTemplates = [
  {
    number: 1,
    name: "Practicums",
    items: [
      {
        number: 1,
        name: "Practicum I: Poison of Black Death",
        icon: "skull-crossbones",
        puzzles: [
          {
            number: 1,
            name: "Candle",
            dice: [
              {
                name: "Fire",
                numberOfDice: 3,
              },
              {
                name: "Water",
                numberOfDice: 1,
              },
              {
                name: "Earth",
                numberOfDice: 2,
              },
              {
                name: "Salt",
                numberOfDice: 2,
              },
            ],
          },
          {
            number: 2,
            name: "Poison of Black Death",
            dice: [
              {
                name: "Fire",
                numberOfDice: 3,
              },
              {
                name: "Water",
                numberOfDice: 3,
              },
              {
                name: "Earth",
                numberOfDice: 3,
              },
              {
                name: "Salt",
                numberOfDice: 3,
              },
            ],
          },
        ],
      },
      {
        number: 2,
        name: "Practicum II: Potion of Wild Growth",
        icon: "seedling",
        puzzles: [
          {
            number: 1,
            name: "Potion of Wild Growth",
            dice: [
              {
                name: "Fire",
                numberOfDice: 3,
              },
              {
                name: "Water",
                numberOfDice: 3,
              },
              {
                name: "Earth",
                numberOfDice: 3,
              },
              {
                name: "Salt",
                numberOfDice: 3,
              },
            ],
          },
          {
            number: 2,
            name: "Divination Table",
            dice: [
              {
                name: "Fire",
                numberOfDice: 1,
              },
              {
                name: "Water",
                numberOfDice: 1,
              },
              {
                name: "Earth",
                numberOfDice: 1,
              },
            ],
          },
        ],
      },
      {
        number: 3,
        name: "Practicum III: Touchstone Brew",
        icon: "coins",
        puzzles: [
          {
            number: 1,
            name: "Potion of Strength",
            dice: [
              {
                name: "Fire",
                numberOfDice: 4,
              },
              {
                name: "Water",
                numberOfDice: 1,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
            ],
          },
          {
            number: 2,
            name: "Potion of Brilliance",
            dice: [
              {
                name: "Water",
                numberOfDice: 5,
              },
              {
                name: "Earth",
                numberOfDice: 1,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
            ],
          },
          {
            number: 3,
            name: "Potion of Invisibility",
            dice: [
              {
                name: "Fire",
                numberOfDice: 2,
              },
              {
                name: "Water",
                numberOfDice: 2,
              },
              {
                name: "Earth",
                numberOfDice: 2,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
            ],
          },
          {
            number: 4,
            name: "Touchstone Brew",
            dice: [
              {
                name: "Fire",
                numberOfDice: 2,
              },
              {
                name: "Water",
                numberOfDice: 2,
              },
              {
                name: "Earth",
                numberOfDice: 2,
              },
              {
                name: "Brimstone",
                numberOfDice: 6,
              },
              {
                name: "Salt",
                numberOfDice: 3,
              },
            ],
          },
          {
            number: 3,
            name: "Dirty Chamber Pot",
            dice: [
              {
                name: "Salt",
                numberOfDice: 10,
              },
            ],
          },
        ],
      },
      {
        number: 4,
        name: "Practicum IV: Gnomebane Potion",
        icon: "carrot",
        puzzles: [
          {
            number: 1,
            name: "Gnomebane Potion",
            dice: [
              {
                name: "Fire",
                numberOfDice: 2,
              },
              {
                name: "Water",
                numberOfDice: 3,
              },
              {
                name: "Earth",
                numberOfDice: 1,
              },
              {
                name: "Brimstone",
                numberOfDice: 2,
              },
              {
                name: "Salt",
                numberOfDice: 7,
              },
              {
                name: "Mineral",
                numberOfDice: 1,
              },
            ],
          },
          {
            number: 2,
            name: "Red Dye",
            dice: [
              {
                name: "Fire",
                numberOfDice: 3,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
              {
                name: "Mineral",
                numberOfDice: 1,
              },
            ],
          },
          {
            number: 3,
            name: "Blue Dye",
            dice: [
              {
                name: "Water",
                numberOfDice: 3,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
              {
                name: "Mineral",
                numberOfDice: 1,
              },
            ],
          },
          {
            number: 4,
            name: "Green Dye",
            dice: [
              {
                name: "Water",
                numberOfDice: 1,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
              {
                name: "Brimstone",
                numberOfDice: 2,
              },
              {
                name: "Mineral",
                numberOfDice: 1,
              },
            ],
          },
        ],
      },
      {
        number: 5,
        name: "Practicum V: Capritongue Elixir",
        icon: "comment-dots",
        puzzles: [
          {
            number: 1,
            name: "Capritongue Elixir",
            dice: [
              {
                name: "Fire",
                numberOfDice: 2,
              },
              {
                name: "Water",
                numberOfDice: 2,
              },
              {
                name: "Earth",
                numberOfDice: 1,
              },
              {
                name: "Salt",
                numberOfDice: 5,
              },
              {
                name: "Brimstone",
                numberOfDice: 2,
              },
              {
                name: "Mineral",
                numberOfDice: 1,
              },
              {
                name: "Air",
                numberOfDice: 3,
              },
            ],
          },
          {
            number: 2,
            name: "Half-dirty Chamber Pot",
            dice: [
              {
                name: "Salt",
                numberOfDice: 6,
              },
            ],
          },
          {
            number: 3,
            name: "Alchemist's Dice",
            dice: [
              {
                name: "Fire",
                numberOfDice: 1,
              },
              {
                name: "Water",
                numberOfDice: 1,
              },
              {
                name: "Earth",
                numberOfDice: 1,
              },
              {
                name: "Brimstone",
                numberOfDice: 1,
              },
            ],
          },
        ],
      },
      {
        number: 6,
        name: "Practicum VI: Gout Ointment",
        icon: "suitcase-medical",
        puzzles: [
          {
            number: 1,
            name: "Gout Ointment",
            dice: [
              {
                name: "Fire",
                numberOfDice: 2,
              },
              {
                name: "Water",
                numberOfDice: 2,
              },
              {
                name: "Earth",
                numberOfDice: 1,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
              {
                name: "Brimstone",
                numberOfDice: 3,
              },
              {
                name: "Mineral",
                numberOfDice: 1,
              },
              {
                name: "Air",
                numberOfDice: 2,
              },
              {
                name: "Arsenic",
                numberOfDice: 2,
              },
            ],
          },
          {
            number: 2,
            name: "Dirty Chamber Pot",
            dice: [
              {
                name: "Salt",
                numberOfDice: 10,
              },
            ],
          },
        ],
      },
      {
        number: 7,
        name: "Practicum VII: Liquid Luck",
        icon: "clover",
        puzzles: [
          {
            number: 1,
            name: "Liquid Luck",
            dice: [
              {
                name: "Fire",
                numberOfDice: 3,
              },
              {
                name: "Water",
                numberOfDice: 2,
              },
              {
                name: "Earth",
                numberOfDice: 2,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
              {
                name: "Brimstone",
                numberOfDice: 2,
              },
              {
                name: "Air",
                numberOfDice: 2,
              },
              {
                name: "Arsenic",
                numberOfDice: 1,
              },
              {
                name: "Iron",
                numberOfDice: 2,
              },
            ],
          },
          {
            number: 2,
            name: "The Royal Lottery",
            dice: [
              {
                name: "Fire",
                numberOfDice: 3,
              },
              {
                name: "Water",
                numberOfDice: 3,
              },
              {
                name: "Earth",
                numberOfDice: 3,
              },
              {
                name: "Salt",
                numberOfDice: 3,
              },
              {
                name: "Brimstone",
                numberOfDice: 3,
              },
            ],
          },
          {
            number: 3,
            name: "Dirty Chamber Pot",
            dice: [
              {
                name: "Salt",
                numberOfDice: 10,
              },
            ],
          },
        ],
      },
      {
        number: 8,
        name: "Practicum VIII: Homunculus in a Bottle",
        icon: "bottle-droplet",
        puzzles: [
          {
            number: 1,
            name: "Potion for Animating Legs",
            dice: [
              {
                name: "Fire",
                numberOfDice: 1,
              },
              {
                name: "Water",
                numberOfDice: 1,
              },
              {
                name: "Earth",
                numberOfDice: 1,
              },
              {
                name: "Mineral",
                numberOfDice: 1,
              },
              {
                name: "Iron",
                numberOfDice: 1,
              },
              {
                name: "Mandrake",
                numberOfDice: 1,
              },
            ],
          },
          {
            number: 2,
            name: "Potion for Animating Arms",
            dice: [
              {
                name: "Fire",
                numberOfDice: 1,
              },
              {
                name: "Water",
                numberOfDice: 1,
              },
              {
                name: "Earth",
                numberOfDice: 1,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
              {
                name: "Brimstone",
                numberOfDice: 1,
              },
              {
                name: "Air",
                numberOfDice: 1,
              },
              {
                name: "Iron",
                numberOfDice: 2,
              },
              {
                name: "Mandrake",
                numberOfDice: 1,
              },
            ],
          },
          {
            number: 3,
            name: "Potion for Animating Head & Torso",
            dice: [
              {
                name: "Earth",
                numberOfDice: 1,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
              {
                name: "Brimstone",
                numberOfDice: 2,
              },
              {
                name: "Air",
                numberOfDice: 1,
              },
              {
                name: "Arsenic",
                numberOfDice: 1,
              },
              {
                name: "Iron",
                numberOfDice: 1,
              },
              {
                name: "Mandrake",
                numberOfDice: 2,
              },
            ],
          },
        ],
      },
      {
        number: 9,
        name: "Practicum IX: Babylon Candles",
        icon: "fire",
        puzzles: [
          {
            number: 1,
            name: "Babylon Candles",
            dice: [
              {
                name: "Fire",
                numberOfDice: 1,
              },
              {
                name: "Water",
                numberOfDice: 1,
              },
              {
                name: "Earth",
                numberOfDice: 1,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
              {
                name: "Brimstone",
                numberOfDice: 2,
              },
              {
                name: "Mineral",
                numberOfDice: 1,
              },
              {
                name: "Air",
                numberOfDice: 1,
              },
              {
                name: "Arsenic",
                numberOfDice: 1,
              },
              {
                name: "Iron",
                numberOfDice: 2,
              },
              {
                name: "Mandrake",
                numberOfDice: 2,
              },
              {
                name: "Quicksilver",
                numberOfDice: 2,
              },
            ],
          },
        ],
      },
      {
        number: 10,
        name: "Practicum X: The Secret of the Parchment",
        icon: "scroll",
        puzzles: [
          {
            number: 1,
            name: "The Royal Seal",
            dice: [
              {
                name: "Fire",
                numberOfDice: 1,
              },
              {
                name: "Water",
                numberOfDice: 1,
              },
              {
                name: "Earth",
                numberOfDice: 1,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
              {
                name: "Brimstone",
                numberOfDice: 1,
              },
              {
                name: "Mineral",
                numberOfDice: 1,
              },
              {
                name: "Air",
                numberOfDice: 1,
              },
              {
                name: "Iron",
                numberOfDice: 1,
              },
              {
                name: "Mandrake",
                numberOfDice: 1,
              },
              {
                name: "Quicksilver",
                numberOfDice: 1,
              },
            ],
          },
          {
            number: 2,
            name: "The Secret of the Parchment",
            dice: [
              {
                name: "Fire",
                numberOfDice: 1,
              },
              {
                name: "Water",
                numberOfDice: 1,
              },
              {
                name: "Earth",
                numberOfDice: 1,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
              {
                name: "Brimstone",
                numberOfDice: 2,
              },
              {
                name: "Mineral",
                numberOfDice: 1,
              },
              {
                name: "Air",
                numberOfDice: 1,
              },
              {
                name: "Arsenic",
                numberOfDice: 2,
              },
              {
                name: "Iron",
                numberOfDice: 2,
              },
              {
                name: "Mandrake",
                numberOfDice: 2,
              },
              {
                name: "Quicksilver",
                numberOfDice: 2,
              },
              {
                name: "Secret",
                numberOfDice: 2,
              },
            ],
          },
        ],
      },
      {
        number: 11,
        name: "Secret Practicum: Amnesia Tonic",
        icon: "question",
        puzzles: [
          {
            number: 1,
            name: "Amnesia Tonic",
            dice: [
              {
                name: "Fire",
                numberOfDice: 1,
              },
              {
                name: "Water",
                numberOfDice: 1,
              },
              {
                name: "Arsenic",
                numberOfDice: 1,
              },
              {
                name: "Iron",
                numberOfDice: 1,
              },
              {
                name: "Mandrake",
                numberOfDice: 1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    number: 2,
    name: "Appendix",
    items: [
      {
        number: 12,
        name: "Library",
        icon: "scroll",
        puzzles: [
          {
            number: 1,
            name: "The First Library Seal",
            dice: [
              {
                name: "Fire",
                numberOfDice: 1,
              },
              {
                name: "Water",
                numberOfDice: 1,
              },
              {
                name: "Earth",
                numberOfDice: 1,
              },
              {
                name: "Salt",
                numberOfDice: 1,
              },
            ],
          },
          {
            number: 2,
            name: "The Second Library Seal",
            dice: [
              {
                name: "Salt",
                numberOfDice: 1,
              },
              {
                name: "Brimstone",
                numberOfDice: 1,
              },
              {
                name: "Mineral",
                numberOfDice: 1,
              },
              {
                name: "Air",
                numberOfDice: 1,
              },
            ],
          },
          {
            number: 3,
            name: "The Third Library Seal",
            dice: [
              {
                name: "Water",
                numberOfDice: 1,
              },
              {
                name: "Arsenic",
                numberOfDice: 1,
              },
              {
                name: "Iron",
                numberOfDice: 1,
              },
              {
                name: "Mandrake",
                numberOfDice: 1,
              },
            ],
          },
          {
            number: 4,
            name: "Trollnip Potion",
            dice: [
              {
                name: "Fire",
                numberOfDice: 2,
              },
              {
                name: "Water",
                numberOfDice: 2,
              },
              {
                name: "Earth",
                numberOfDice: 1,
              },
              {
                name: "Salt",
                numberOfDice: 2,
              },
              {
                name: "Brimstone",
                numberOfDice: 2,
              },
              {
                name: "Air",
                numberOfDice: 1,
              },
            ],
          },
        ],
      },
      {
        number: 13,
        name: "Parchments",
        icon: "scroll",
        puzzles: [
          {
            number: 1,
            name: "Imp potion",
            dice: [
              {
                name: "Fire",
                numberOfDice: 3,
              },
              {
                name: "Water",
                numberOfDice: 3,
              },
            ],
          },
        ],
      },
    ],
  },
];
