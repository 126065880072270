import { dicetypes } from "./data/dicetypes";

export function constructPuzzleData(practicumNumber, puzzle) {
  //console.log("constructPuzzleData", practicumNumber, puzzle);

  let puzzleData = {
    title: puzzle.name,
    diceContainers: [],
    dice: [],
    diceOptions: {
      flipOptionVisible: false,
      reRollOptionVisible: false,
      wrapOptionVisible: false,
    },
  };

  puzzle.dice.forEach((die) => {
    let dieType = dicetypes.filter(
      (d) => d.type === die.name.toLocaleLowerCase()
    )[0];

    let diceToAdd = [...Array(die.numberOfDice)].map((item, index) => ({
      id: `${die.name.toLocaleLowerCase()}-${index}`,
      type: dieType.type,
      numberOfFaces: dieType.numberOfFaces,
      selectedValue: 1,
      isRolling: false,
      isAssigned: false,
      isReserved: false,
      isWrapped: false,
    }));

    puzzleData.diceContainers.push({
      name: die.name,
      type: dieType.type,
      //   type: "ingredient",
    });

    puzzleData.dice = [...puzzleData.dice, ...diceToAdd];

    if (die.name === "Earth") {
      puzzleData.diceOptions.flipOptionVisible = true;
    }

    if (die.name === "Brimstone") {
      puzzleData.diceOptions.reRollOptionVisible = true;
    }
  });

  if (practicumNumber >= 2) {
    puzzleData.diceOptions.wrapOptionVisible = true;
  }

  puzzleData.diceContainers.push({
    name: "Volatility",
    type: "volatility",
  });

  puzzleData.dice.push({
    id: "volatility-0",
    type: "volatility",
    numberOfFaces: 6,
    selectedValue: 1,
    isAssigned: false,
    isReserved: false,
    isWrapped: false,
  });

  puzzleData.diceContainers.push({
    name: "Assigned",
    type: "assigned",
    dice: [],
  });

  puzzleData.diceContainers.push({
    name: "Reserved",
    type: "reserved",
    dice: [],
  });

  return puzzleData;
}

export function getSymbolUnicode(type) {
  switch (type) {
    case "salt":
      return "🜔";
    case "fire":
      return "🜂";
    case "water":
      return "🜄";
    case "earth":
      return "🜃";
    case "brimstone":
      return "🜏";
    case "mineral":
      return "🜿";
    case "air":
      return "🜁";
    case "arsenic":
      return "🜺";
    case "iron":
      return "♂";
    case "mandrake":
      return "♁";
    case "quicksilver":
      return "☿";
    case "secret":
      return "⎊";
    default:
    // do nothing
  }
}

export function getSymbolEscapeSequence(type) {
  //console.log("getSymbolEscapeSequence", type);
  switch (type) {
    case "salt":
      return "\u1F714";
    case "fire":
      return "\u1F702";
    case "water":
      return "\u1F704";
    case "earth":
      return "\u1F703";
    case "brimstone":
      return "\u1F70F";
    case "mineral":
      return "\u1F73F";
    case "air":
      return "\u1F701";
    case "arsenic":
      return "\u1F73A";
    case "iron":
      return "\u2642";
    case "mandrake":
      return "\u1F72B";
    case "quicksilver":
      return "\u263F";
    case "secret":
      return "\u238A";
    default:
    // do nothing
  }
}

export function unicodeToChar(text) {
  console.log("unicodeToChar", text);
  return text.replace(/\\u[\dA-F]{4}/gi, function (match) {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
  });
}

export function getRandomInt(max) {
  let min = 1; // minimum is inclusive
  max = max + 1; // maximum is exclusive, so add 1
  return Math.floor(Math.random() * (max - min) + min);
}
