import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import "./diecontainer.css";
import Die from "../die/die";
import { getSymbolUnicode } from "../../functions";

export default function DieContainer(props) {
  //console.log("DieContainer", props);

  const {
    name,
    type,
    dice,
    displaySymbol,
    changeDieValue,
    toggleAssigned,
    toggleReserved,
    toggleWrapped,
    reRollOptionVisible,
    reRollDie,
    flipDie,
    wrapOptionVisible,
    flipOptionVisible,
  } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <div className="die-container">
        <div className="die-container-title">
          {displaySymbol ? (
            <>
              <span
                id={`die-container-title-${name}`}
                className="die-container-symbol-title"
              >
                {getSymbolUnicode(type)}
              </span>
              <Tooltip
                autohide={true}
                flip={true}
                isOpen={tooltipOpen}
                target={`die-container-title-${name}`}
                toggle={toggleTooltip}
              >
                {type}
              </Tooltip>
            </>
          ) : (
            <span className="die-container-text-title">{name}</span>
          )}
        </div>
        <div>
          {dice.map((die, i) => (
            <Die
              key={die.id}
              id={die.id}
              type={die.type}
              selectedValue={die.selectedValue}
              isRolling={die.isRolling}
              isAssigned={die.isAssigned}
              isReserved={die.isReserved}
              isWrapped={die.isWrapped}
              numberOfFaces={die.numberOfFaces}
              changeDieValue={changeDieValue}
              toggleAssigned={toggleAssigned}
              toggleReserved={toggleReserved}
              toggleWrapped={toggleWrapped}
              reRollOptionVisible={reRollOptionVisible}
              reRollDie={reRollDie}
              flipDie={flipDie}
              wrapOptionVisible={wrapOptionVisible}
              flipOptionVisible={flipOptionVisible}
            />
          ))}
          {dice.length === 0 && (
            <span className="die-container-empty">(empty)</span>
          )}
        </div>
      </div>
    </>
  );
}
