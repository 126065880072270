import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DieContainer from "../../components/diecontainer/diecontainer";
import { constructPuzzleData, getRandomInt } from "../../functions";
import "./puzzle.css";

export default function Puzzle(props) {
  // console.log("PuzzlePage", props);

  // constants
  const { practicumNumber, puzzle, setPuzzle } = props;

  // state
  const [puzzleStarted, setPuzzleStarted] = useState(false);
  const [intiailiseComplete, setIntiailiseComplete] = useState(false);
  const [puzzleData, setPuzzleData] = useState();
  const [firstRollComplete, setFirstRollComplete] = useState(false);
  const [resetModalIsOpen, setResetModalIsOpen] = useState(false);
  const [helpModalIsOpen, setHelpModalIsOpen] = useState(false);

  // effects

  useEffect(() => {
    if (!intiailiseComplete) {
      var puzzleData = constructPuzzleData(practicumNumber, puzzle);
      setPuzzleData(puzzleData);
      setIntiailiseComplete(true);
    }
  });

  // functions

  function startPuzzle() {
    setPuzzleStarted(true);
    rollDice();
  }

  function toggleResetModal() {
    setResetModalIsOpen(!resetModalIsOpen);
  }

  function handleResetClick() {
    setResetModalIsOpen(true);
  }

  function handleResetModalAction(reset) {
    setResetModalIsOpen(false);

    if (reset) {
      resetDice();
    }
  }

  function resetDice() {
    setPuzzleStarted(false);
    setFirstRollComplete(false);
    var defaultData = constructPuzzleData(practicumNumber, puzzle);
    setPuzzleData(defaultData);
  }

  function rollDice() {
    toggleRollingAnimation(true);

    var newData = { ...puzzleData };

    newData.dice
      .filter(
        (i) =>
          i.type !== "volatility" &&
          (i.type !== "mineral" ||
            (i.type === "mineral" && !firstRollComplete)) &&
          !i.isAssigned
      )
      .forEach((die) => {
        if (die.isReserved) {
          die.isReserved = false;
        } else {
          let newValue = getRandomInt(die.numberOfFaces);
          die.selectedValue = newValue;
          die.isWrapped = false;
        }
      });

    setPuzzleData(newData);

    setFirstRollComplete(true);

    setTimeout(() => {
      toggleRollingAnimation(false);
    }, 1000);
  }

  function changeDieProperty(dieId, propertyName, newValue) {
    //console.log("changeDieProperty", dieId, propertyName, newValue);
    var newData = { ...puzzleData };

    let die = newData.dice.filter((d) => d.id === dieId);

    if (die.length === 1) {
      die[0][propertyName] = newValue;
    }

    setPuzzleData(newData);
  }

  function changeDieValue(dieId, newValue) {
    changeDieProperty(dieId, "selectedValue", newValue);
  }

  function toggleRollingAnimation(isRolling, dieId) {
    if (dieId) {
      puzzleData.dice
        .filter((i) => i.id === dieId)
        .forEach((die) => {
          changeDieProperty(die.id, "isRolling", isRolling);
        });
    } else {
      puzzleData.dice
        .filter(
          (i) =>
            i.type !== "volatility" &&
            (i.type !== "mineral" ||
              (i.type === "mineral" && !firstRollComplete)) &&
            !i.isAssigned &&
            !i.isReserved
        )
        .forEach((die) => {
          changeDieProperty(die.id, "isRolling", isRolling);
        });
    }
  }

  function reRollDie(dieId) {
    toggleRollingAnimation(true, dieId);

    let die = puzzleData.dice.filter((i) => i.id === dieId)[0];
    let newValue = getRandomInt(die.numberOfFaces);
    changeDieValue(dieId, newValue);

    setTimeout(() => {
      toggleRollingAnimation(false, dieId);
    }, 1000);
  }

  function toggleAssigned(dieId, assigned) {
    changeDieProperty(dieId, "isAssigned", assigned);
    if (assigned) {
      changeDieProperty(dieId, "isReserved", false);
      changeDieProperty(dieId, "isWrapped", false);
    }
  }

  function toggleReserved(dieId, reserved) {
    changeDieProperty(dieId, "isReserved", reserved);
  }

  function toggleWrapped(dieId, wrapped) {
    changeDieProperty(dieId, "isWrapped", wrapped);
  }

  function flipDie(dieId) {
    let die = puzzleData.dice.filter((i) => i.id === dieId)[0];
    let newValue = die.numberOfFaces + 1 - die.selectedValue;
    changeDieValue(dieId, newValue);
  }

  function toggleHelpModal() {
    setHelpModalIsOpen(!helpModalIsOpen);
  }

  return (
    <>
      {puzzleData && (
        <Container>
          <Row className="justify-content-left mt-3">
            <Col>
              <Button
                color="secondary"
                className="float-start"
                onClick={() => setPuzzle(null)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left-circle"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                  />
                </svg>
                &nbsp; Back
              </Button>
              <Button
                color="info"
                className="float-end"
                onClick={toggleHelpModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-info-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
                &nbsp; Help
              </Button>
            </Col>
          </Row>
          <Row className="justify-content-center text-center mt-3">
            <Col sm={6}>
              <h1 className="display-5">{puzzleData.title}</h1>
            </Col>
          </Row>

          {!puzzleStarted && (
            <>
              <Row className="justify-content-center text-center mt-3">
                <Col className="col-6 col-sm-4 d-grid gap-2 mx-auto">
                  <Button color="success" onClick={() => startPuzzle()}>
                    Start puzzle
                  </Button>
                </Col>
              </Row>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </>
          )}

          {puzzleStarted && (
            <>
              <Row className="justify-content-center text-center">
                {/* VOLATILITY DIE */}
                <Col className="col-12 col-sm-6">
                  {puzzleData.diceContainers
                    .filter((d) => d.type === "volatility")
                    .map((container) => (
                      <DieContainer
                        key={`container-${container.type}`}
                        type={container.type}
                        name={container.name}
                        dice={puzzleData.dice.filter(
                          (d) => d.type === "volatility"
                        )}
                        displaySymbol={false}
                        changeDieValue={changeDieValue}
                        toggleAssigned={toggleAssigned}
                        reRollOptionVisible={
                          puzzleData.diceOptions.reRollOptionVisible
                        }
                        reRollDie={reRollDie}
                        flipDie={flipDie}
                        wrapOptionVisible={false}
                        flipOptionVisible={
                          puzzleData.diceOptions.flipOptionVisible
                        }
                      />
                    ))}
                </Col>
                <Col className="col-12 col-sm-6">
                  {/* RESERVED DICE */}
                  {puzzleData.diceContainers
                    .filter((d) => d.type === "reserved")
                    .map((container) => (
                      <DieContainer
                        key={`container-${container.type}`}
                        type={container.type}
                        name={container.name}
                        dice={puzzleData.dice.filter((die) => die.isReserved)}
                        displaySymbol={false}
                        changeDieValue={changeDieValue}
                        toggleAssigned={toggleAssigned}
                        toggleReserved={toggleReserved}
                        toggleWrapped={toggleWrapped}
                        reRollDie={reRollDie}
                        wrapOptionVisible={false}
                        flipOptionVisible={false}
                      />
                    ))}
                </Col>
              </Row>

              {/* INGREDIENTS DICE */}
              <Row className="justify-content-center text-center">
                <Col className="col">
                  <div className="die-container">
                    <div className="die-container-text-title mt-2">
                      Unassigned ingredients
                    </div>

                    <Row className="justify-content-center text-center mt-3">
                      <Col className="col-6 col-sm-4 d-grid gap-2 mx-auto">
                        <Button color="primary" onClick={() => rollDice()}>
                          Stir
                        </Button>
                      </Col>
                    </Row>
                    <Row className="justify-content-center text-center">
                      {puzzleData.diceContainers
                        .filter(
                          (container) =>
                            container.type !== "volatility" &&
                            container.type !== "reserved" &&
                            container.type !== "assigned"
                        )
                        .map((container) => (
                          <Col
                            className="col-6 col-sm-4"
                            key={`col-container-${container.type}`}
                          >
                            <DieContainer
                              key={`container-${container.type}`}
                              type={container.type}
                              name={container.name}
                              dice={puzzleData.dice.filter(
                                (die) =>
                                  die.type === container.type &&
                                  !die.isAssigned &&
                                  !die.isReserved
                              )}
                              displaySymbol={true}
                              changeDieValue={changeDieValue}
                              toggleAssigned={toggleAssigned}
                              toggleReserved={toggleReserved}
                              toggleWrapped={toggleWrapped}
                              reRollOptionVisible={
                                puzzleData.diceOptions.reRollOptionVisible
                              }
                              reRollDie={reRollDie}
                              flipDie={flipDie}
                              wrapOptionVisible={
                                puzzleData.diceOptions.wrapOptionVisible
                              }
                              flipOptionVisible={
                                puzzleData.diceOptions.flipOptionVisible
                              }
                            />
                          </Col>
                        ))}
                    </Row>
                  </div>
                </Col>
              </Row>

              {/* ASSIGNED DICE */}
              <Row className="row justify-content-center text-center">
                <Col>
                  {puzzleData.diceContainers
                    .filter((d) => d.type === "assigned")
                    .map((container) => (
                      <DieContainer
                        key={`container-${container.type}`}
                        type={container.type}
                        name={container.name}
                        dice={puzzleData.dice.filter((die) => die.isAssigned)}
                        displaySymbol={false}
                        changeDieValue={changeDieValue}
                        toggleAssigned={toggleAssigned}
                        wrapOptionVisible={false}
                        flipOptionVisible={false}
                      />
                    ))}
                </Col>
              </Row>

              <Row className="justify-content-center text-center mt-3 mb-3">
                <Col className="col-6 col-sm-4 d-grid gap-2 mx-auto">
                  <Button color="danger" onClick={() => handleResetClick()}>
                    Reset puzzle
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Container>
      )}

      <Modal
        isOpen={resetModalIsOpen}
        toggle={toggleResetModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={toggleResetModal}>Are you sure?</ModalHeader>
        <ModalBody>Are you sure you want to reset this puzzle?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => handleResetModalAction(true)}>
            Reset
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => handleResetModalAction(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={helpModalIsOpen}
        toggle={toggleHelpModal}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader toggle={toggleHelpModal}>
          How to use the companion app
        </ModalHeader>
        <ModalBody>
          <ol>
            <li>
              <p>
                Select a puzzle and hit the "Start" button - the app will
                display and roll all dice required for the chosen puzzle.
              </p>
            </li>
            <li>
              <p>
                Open the potion formula in any graphic editor and mark the
                values of assigned ingredients on the formula nodes as you go.
                For a physical copy, use a pencil or a dry-erase marker on a
                laminated sheet to mark assigned values.
              </p>
            </li>
            <li>
              <p>
                Click any ingredient die or the volatility die to interact with
                it; a pop-up menu will allow you to:
                <ul>
                  <li>
                    change a die's face to any value (this may happen as a
                    result of a placement effect applied to the die, or
                    volatility increase before a stir)
                  </li>
                  <li>
                    assign an ingredient die (mark it on your formula when you
                    do it)
                  </li>
                  <li>reserve an ingredient before a stir</li>
                  <li>
                    mark a die as wrapped (mark arsenic only after it's been
                    multi-wrapped to avoid confusion)
                  </li>
                  <li>flip a die (if earth dice are present)</li>
                  <li>re-roll a die (if brimstone is present)</li>
                </ul>
              </p>
            </li>
            <li>
              <p>
                The app is fairly manual; for example, if you assign a fire die,
                you need to manually choose a different die to be increased, set
                it to the new value, mark it as wrapped if required, and
                manually increase the volatility if a wrap had occurred.
              </p>
            </li>
            <li>
              <p>
                Hit the “Stir” button to re-roll all unreserved dice,
                remembering to manually increase volatility. All reserved dice
                will be automatically unreserved after a stir.
              </p>
            </li>
          </ol>
          Good luck!
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleHelpModal}>
            Got it!
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
