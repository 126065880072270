import React from "react";
import iconBgg from "../../assets/images/social-icons/icon-bgg.png";

export default function Footer(props) {
  const {} = props;

  return (
    <>
      <footer className="footer py-5 mt-5 bg-light">
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-1 mx-2">
              <a
                href="https://boardgamegeek.com/user/medusagaming"
                target="_blank"
              >
                <img
                  src={iconBgg}
                  alt="Medusa Gaming Logo"
                  className="d-inline-block align-text-top"
                  style={{ maxWidth: "2em", maxHeight: "2.2em" }}
                />
              </a>
            </div>
            <div className="col-1 mx-2">
              <a
                href="https://www.instagram.com/medusa.tabletop/"
                target="_blank"
              >
                <i
                  className="bi bi-instagram"
                  style={{ fontSize: "2rem", color: "black" }}
                ></i>
              </a>
            </div>
            <div className="col-1 mx-2">
              <a
                href="https://steamcommunity.com/id/v1nd1cator"
                target="_blank"
              >
                <i
                  className="bi bi-steam"
                  style={{ fontSize: "2rem", color: "black" }}
                ></i>
              </a>
            </div>
            <div className="col-1 mx-2">
              <a
                href="https://www.facebook.com/medusa.tabletop"
                target="_blank"
              >
                <i
                  className="bi bi-facebook"
                  style={{ fontSize: "2rem", color: "black" }}
                ></i>
              </a>
            </div>
            <div className="col-1 mx-2">
              <a
                href="https://www.linkedin.com/in/denis-phoenix/"
                target="_blank"
              >
                <i
                  className="bi bi-linkedin"
                  style={{ fontSize: "2rem", color: "black" }}
                ></i>
              </a>
            </div>
            <div className="col-1 mx-2">
              <a href="mailto:perseus@medusagaming.org" target="_blank">
                <i
                  className="bi bi-envelope"
                  style={{ fontSize: "2rem", color: "black" }}
                ></i>
              </a>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col text-end">
              <small className="text-muted">
                © 2022 Medusa Gaming. All rights reserved.
              </small>
            </div>
          </div>
        </div>
      </footer>
      <div style={{ position: "static !important" }}></div>
    </>
  );
}
