import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
} from "reactstrap";
import Puzzle from "../../components/puzzle/puzzle";
import { practicumTemplates } from "../../data/data";
import aacover from "../../assets/images/alchemists-apprentice/aa-cover-reduced-quality.jpg";

export default function AaCompanion(props) {
  // console.log("AaCompanion", props);

  const [puzzle, setPuzzle] = useState();
  const [practicumNumber, setPracticumNumber] = useState();
  const [practicumData, setPracticumData] = useState(practicumTemplates);
  const [open, setOpen] = useState("");

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  function updatePuzzle(practicumNumber, puzzle) {
    setPracticumNumber(practicumNumber);
    setPuzzle(puzzle);
  }

  return (
    <>
      <section className="text-center container">
        <div className="row">
          <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
            <h1 className="display-4">Alchemist's Apprentice companion app</h1>
          </div>
        </div>
      </section>

      {!puzzle && (
        <Container>
          <Row className="mt-3">
            <Col
              xs={12}
              sm={12}
              md={6}
              className="order-xs-1 order-sm-1 order-md-2"
            >
              {practicumData.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <h1 className="display-5">{section.name}</h1>
                  <Accordion flush open={open} toggle={toggle}>
                    {section.items.map((item, itemIndex) => (
                      <AccordionItem key={`${sectionIndex}-${itemIndex}`}>
                        <AccordionHeader
                          targetId={`${sectionIndex}-${itemIndex}`}
                        >
                          {item.name}
                        </AccordionHeader>
                        <AccordionBody
                          accordionId={`${sectionIndex}-${itemIndex}`}
                        >
                          {item.puzzles.map((puzzle, puzzleIndex) => (
                            <div
                              className="mt-1"
                              key={`${sectionIndex}-${itemIndex}-${puzzleIndex}`}
                            >
                              <Button
                                color={"link"}
                                onClick={() =>
                                  updatePuzzle(item.number, puzzle)
                                }
                              >
                                {puzzle.name}
                              </Button>
                            </div>
                          ))}
                        </AccordionBody>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
              ))}
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              className="order-xs-2 order-sm-2 order-md-1"
            >
              <img className="img-fluid die-container-symbol" src={aacover} />
            </Col>
          </Row>
        </Container>
      )}

      {puzzle && (
        <Puzzle
          practicumNumber={practicumNumber}
          puzzle={puzzle}
          setPuzzle={setPuzzle}
        />
      )}
    </>
  );
}
