import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";
import "./home.css";
import aacover from "../../assets/images/alchemists-apprentice/aa-cover-reduced-quality.jpg";
import denisProfilePic from "../../assets/images/denis-profile-pic-reduced-quality.jpg";
import logoBlizzard from "../../assets/images/external-logos/logo-blizzard.png";
import logoCalArts from "../../assets/images/external-logos/logo-calarts.png";
import logoLionheadStudios from "../../assets/images/external-logos/logo-lionhead-studios.png";
import logoMonolith from "../../assets/images/external-logos/logo-monolith.png";
import logoUbisoft from "../../assets/images/external-logos/logo-ubisoft.png";
import logoValve from "../../assets/images/external-logos/logo-valve.png";
import logoXboxGameStudios from "../../assets/images/external-logos/logo-xbox-game-studios.png";

export default function Home(props) {
  const {} = props;

  return (
    <>
      <div className="container marketing py-4">
        {/* INTRO TEXT  */}
        <section className="text-center container">
          <div className="row">
            <div className="col-sm-12 col-md-10 col-lg-9 col-xl-8 mx-auto">
              <h1 className="display-4">Medusa Gaming</h1>
              <p className="lead">
                Experts at Medusa strive to create captivating adventures,
                conjure spellbinding stories, and forge compelling designs. We
                draw on our experience in good game design and our enthusiasm
                fuelled by our passion for video and tabletop games.
              </p>
              <p className="lead">
                We know that the cake is a lie and the princess is in another
                castle.
              </p>
              {/* <p>
                <a href="#" className="btn btn-primary my-2">Learn more</a>
              </p> */}
            </div>
          </div>
        </section>

        {/* ALCHEMIST'S APPRENTICE  */}
        <div className="p-4 mb-4 bg-light rounded-3 mt-2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-7 order-1 order-xs-2 order-sm-2 order-md-2">
                <div className="row mt-3">
                  <h1 className="display-5 fw-bold">Alchemist's Apprentice</h1>
                </div>
                <p className="fs-5">
                  Put on the robe of an apprentice of the greatest science of
                  all, alchemy. Mandrakes and wyvern wishbones, boiling
                  cauldrons and exploding potions, trolls and gnomes, a grumpy
                  old alchemist, a playful narrator, and an ever-happy goat
                  await you!
                </p>
                <p className="fs-5">
                  <span className="fw-bold">Alchemist's Apprentice</span> is a
                  solo dice-rolling, puzzle-solving narrative adventure brimming
                  with fantasy. The player will be rolling dice representing
                  alchemical ingredients and incorporating them into potions,
                  solving the puzzle of their formulae requirements. Each
                  ingredient has special properties, which allow the player to
                  affect other dice and impact the volatility of the potion,
                  bringing logic and deduction to the forefront of the game.
                </p>
                <div className="mt-2">
                  <a
                    href="https://medusagaming.itch.io/alchemists-apprentice"
                    target="_blank"
                    className="link-primary"
                  >
                    Download on Itch
                  </a>
                </div>
                <div className="mt-2">
                  <a
                    href="https://boardgamegeek.com/boardgame/369119/alchemists-apprentice"
                    target="_blank"
                    className="link-primary"
                  >
                    BoardGameGeek page
                  </a>
                </div>
                <div className="mt-2">
                  <a href="/aa-companion" className="link-primary">
                    Companion app
                  </a>
                </div>
                {/* <!-- <div className="mt-4">
                  <button className="btn btn-primary" type="button">
                    Learn more
                  </button>
                </div> --> */}

                {/* <!-- <button className="btn btn-success" type="button">
                  Download on Itch
                </button>
                <button className="btn btn-success" type="button">
                  BoardGameGeek page
                </button>
                <button className="btn btn-success" type="button">
                  Companion app
                </button> --> */}
              </div>
              <div className="col-md-8 col-lg-5 order-lg-2 order-xs-1 order-sm-1 order-md-1">
                <img
                  src={aacover}
                  className="img-fluid"
                  style={{ borderRadius: "3px" }}
                  alt="Alchemist's Apprentice Cover"
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="featurette-divider" />

        {/* <!-- MEET US --> */}
        <div className="row my-5 align-items-center">
          <div className="col-md-8 col-lg-8 order-md-2">
            <h1 className="display-4">Meet our design wizard</h1>
            <p className="lead">
              Games have been Denis’ passion ever since he could tell the die
              faces. In his teenage years, Denis worked as a video game news
              publisher and editor-in-chief by day and played on a cybersports
              team by night. He continued pursuing his gaming passion as he
              studied linguistics and development systems to gain the expertise
              that allowed him to work as a localizer and QA on many renowned
              video game series, such as World of Warcraft, Diablo, Assassin's
              Creed, Dota, Fable, and Shadow of Mordor.
            </p>
            <p className="lead">
              Over the last few years, Denis has been studying game design and
              creative writing while volunteering as a game awards judge to
              further his knowledge of good game design. His experiences
              culminated in 2022, when he designed and produced Medusa’s first
              game, Alchemist’s Apprentice.
            </p>
            <p className="lead">
              <span className="fw-bold">Favourite games:</span> Hades, Clank!:
              Legacy, Return of the Obra Dinn, Portal, 7th Continent
            </p>
            {/* <!-- <p>
              <a href="#" className="btn btn-primary my-2">Learn more</a>
            </p> --> */}
          </div>
          <div className="col-md-4 col-lg-4 order-md-1 text-center">
            <img
              src={denisProfilePic}
              alt="Denis Avatar"
              className="d-inline-block align-text-top"
              style={{ maxHeight: "20em", borderRadius: "3px" }}
            />
          </div>
        </div>

        <hr className="featurette-divider" />

        {/* <!-- OUR EXPERIENCE --> */}
        <div className="row my-5">
          <div className="col-md-12">
            <h1 className="display-4">Our experience</h1>
            <p className="lead">
              Our experts were involved in projects created by these titans.
            </p>
            <div className="row align-items-center text-center mt-5">
              <div className="col">
                <img
                  src={logoUbisoft}
                  className="img-fluid rounded logo-image"
                  alt="Ubisoft Logo"
                />
              </div>
              <div className="col">
                <img
                  src={logoBlizzard}
                  className="img-fluid rounded logo-image"
                  alt="Blizzard Logo"
                />
              </div>
              <div className="col">
                <img
                  src={logoXboxGameStudios}
                  className="img-fluid rounded logo-image"
                  alt="Xbox Game Studios Logo"
                />
              </div>
              <div className="col">
                <img
                  src={logoValve}
                  className="img-fluid rounded logo-image"
                  alt="Valve Logo"
                />
              </div>
              <div className="col">
                <img
                  src={logoMonolith}
                  className="img-fluid rounded logo-image"
                  alt="Monolith Logo"
                />
              </div>
              <div className="col">
                <img
                  src={logoLionheadStudios}
                  className="img-fluid rounded logo-image"
                  alt="Lionhead Studios Logo"
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="featurette-divider" />

        {/* <!-- CERTIFICATIONS --> */}
        <div className="row my-5 align-items-center">
          <div className="col-md-8 order-md-1">
            <h1 className="display-4">
              Certified by California Institute of the Arts
            </h1>
            <p className="lead">
              Medusa is certified by CalArts in foundations of game design
              principles, story and narrative development for games, world
              design for games, character design for games, and art and concepts
              of game design documentation.
            </p>
          </div>
          <div className="col-md-4 order-md-2 text-center">
            <img
              src={logoCalArts}
              alt="CalArts Logo"
              className="d-inline-block align-middle"
              style={{
                maxWidth: "10em",
                maxHeight: "10em",
                borderRadius: "3px",
              }}
            />
          </div>
        </div>
      </div>

      {/* <!-- JavaScript Bundle with Popper --> */}
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-A3rJD856KowSb7dwlZdYEkO39Gagi7vIsF0jrRAoQmDKKtQBHUuLZ9AsSv4jD4Xa"
        crossOrigin="anonymous"
      ></script>

      <div style={{ position: "static !important" }}></div>
    </>
  );
}
